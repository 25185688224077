<template>
  <div class="row">
    <div class="col-md-6">
      <div class="container-grid__item">
        <div class="container-shadow p-24 fill-white align-items-center lead">
          <div class="lead__image">
            <div class="avatar avatar_big mr-24 container-shadow"
                 :style="{backgroundImage: 'url(/pic/osipov.jpg)'}"></div>
          </div>
          <div class="lead-content content-18">
            <div class="contact__name">Дмитрий Осипов</div>
            <div class="contact__post">Руководитель проекта по экспортному наставничеству</div>
            <div class="contact__phone">
              +7 (495) 937-47-47 (доб.1344)<br/>
              +7 (960) 859-60-92
            </div>
            <div class="contact__mail"><a href="mailto:d.osipov@exportcenter.ru">d.osipov@exportcenter.ru</a></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="container-grid__item">
        <h2 class="mb-4 section__how-learn-description-title">
          Мы <span class="content_red">знаем</span> как выходить<br/> на <span class="content_red">экспорт</span> и готовы<br/>  <span class="content_red">помочь</span> Вам <span class="content_red">пройти этот путь</span>!
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MentoringContact"
}
</script>

<style scoped>

</style>