<template>
  <div class="section__how-learn-basic" style="padding-top: 0;">
    <h2 class="mb-24">Наставник - кто он?</h2>
    <div class="section__acceleration-description-text mb-4">
      <p>Наставник — это профессионал-практик в сфере ВЭД, который окажет вам индивидуальную консультационную поддержку на всех этапах реализации экспортного проекта</p>
      <p>Модель наставничества создана и развивается Школой экспорта РЭЦ с 2018 года:</p>
      <ul>
        <li>профессиональная экспертиза с отраслевой и страновой спецификой (более 50 стран)</li>
        <li>практический опыт экспортного сопровождения более 1300 компаний</li>
        <li>заключены контракты на сумму 248,7 млн долларов США</li>
        <li>наличие собственной широкой сети активных профессиональных контактов</li>
        <li>практика применения наиболее эффективных инструментов господдержки (финансовых и нефинансовых услуг Группы РЭЦ и ЦПЭ)</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MentorDescription"
}
</script>

<style scoped>

</style>