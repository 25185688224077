// @ts-nocheck
import {TokenService} from "../../services/token.service";
import ApiService from "../../services/api.service";

export default {
    methods: {
        setRole(type) {
            if(TokenService.getUser()) {
                ApiService.post('/mentoring/roles', {role: type})
                    .then(res => {
                        if(res.status === 200) {
                            ApiService.get('user').then(res => {
                                TokenService.setUser(res.data)
                                window.location.href = '/profile/mentoring/first'
                            })
                        }
                    })
            } else {
                localStorage.setItem('hasRoleMentoring', type)
                window.location.href = '/user/register-mentoring?type=' + type
            }
        }
    }
}