<template>
  <div style="margin: 12rem;" class="section__acceleration-content">
    <h2 class="mb-5">
      Услуга <span class="content_red">«Экспортное наставничество»</span> включает 3 этапа
    </h2>
    <div class="section__acceleration-content-steps">
      <div class="section__acceleration-content-step">
        <div class="section__acceleration-content-step-wrapper">
          <h2>ЭТАП №1</h2>
          <div class="sub-title">Аналитическое исследование перспектив выхода на экспорт</div>
          <div class="item-content">
            Экспортный наставник подготовит аналитический отчет по вашему товару со статистикой и информацией по барьерам, подготовит рекомендации по выбору перспективных рынков для проработки экспортного проекта на следующем этапе.
          </div>
          <div class="item-content">
            <b>Срок:</b> <span class="content_red">20 рабочих дней</span>
          </div>
        </div>
      </div>
      <div class="section__acceleration-content-step">
        <div class="section__acceleration-content-step-wrapper">
          <h2>ЭТАП №2</h2>
          <div class="sub-title">
            Комплексная дорожная карта
          </div>
          <div class="item-content">
            Экспортный наставник совместно с компанией разработают устав экспортного проекта, календарный план реализации экспортного проекта, ресурсную карту - матрицу распределения ответственности экспортной команды компании по реализации экспортного проекта, а также сформируют план обязательных расходов для реализации экспортного проекта.
          </div>
          <div class="item-content">
            <b>Срок:</b> <span class="content_red">30 рабочих дней</span>
          </div>
        </div>
      </div>
      <div class="section__acceleration-content-step">
        <div class="section__acceleration-content-step-wrapper">
          <h2>ЭТАП №3</h2>
          <div class="sub-title">Сопровождение комплексной дорожной карты</div>
          <div class="item-content">
            В рамках 3 этапа вам будет доступен целый ряд профильных консультаций по способу выхода на иностранный рынок, поиску, оценке и проверке потенциальных иностранных покупателей, сертификации продукции, защите интеллектуальной собственности на внешнем рынке и т.д. (более 20 тематических консультаций)
          </div>
          <div class="item-content">
            <b>Срок:</b> <span class="content_red">до 10 месяцев</span>
          </div>
        </div>
      </div>
    </div>
    <a id="callmeExport" href="#" @click.prevent="setRole('ROLE_CRM_MENTORING_CLIENT')" class="link-target mb-5"><span>ПОДАТЬ ЗАЯВКУ НА ПОЛУЧЕНИЕ УСЛУГИ</span></a>
  </div>
</template>

<script>
import setMentoringRoleMixin from "@/mixins/setMentoringRoleMixin";

export default {
  name: "MentoringSteps",
  mixins: [setMentoringRoleMixin]
}
</script>

<style scoped>

</style>