<template>
  <div>
    <div v-if="tutors" class="container-grid section__coaches-page-coach">
      <div v-for="tutor in tutors" :key="tutor.id" class="container-grid__item grid-2">
        <tutor-item :key="tutor.id"
                    :tutor="tutor"
                    :show-status="false"
                    :show-experience="false"
                    :show-achievements="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../services/api.service";
import TutorItem from "@/components/tutors/TutorItem.vue";

export default {
  name: "MentorList",
  components: {TutorItem},
  data() {
    return {
      tutors: null
    }
  },
  async mounted() {
    this.tutors = (await ApiService.get('/tutors', {
      params: {
        'filters[id]': '1857,23,166,447,270,27,267,43,138,32,178,453,454,455',
        limit: 100,
        order: 'lastname'
      }
    })).data.tutors
  }
}
</script>

<style scoped>

</style>