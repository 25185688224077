<template>
  <section class="section section__coaches" id="section4">
    <div class="section__coaches-wrapper">
      <div class="title-with-icon mb-2">
        <div class="title-with-icon__icon title-with-icon__icon_coach"></div>
        <div class="title-with-icon__title">Наши наставники</div>
      </div>
      <mentor-list />
    </div>
  </section>
</template>

<script>
import MentorList from "@/components/tutors/MentorList.vue";

export default {
  name: "MentoringMentors",
  components: {MentorList}
}
</script>

<style scoped>

</style>