<template>
  <span>
    <div class="section section__how-learn" style="padding-bottom: 0;">
      <div class="section__wrapper">
        <div class="title-with-icon mb-4">
          <div class="title-with-icon__icon title-with-icon__icon_how-learn"></div>
          <div class="title-with-icon__title">Экспортное наставничество</div>
        </div>
        <about-mentoring />
        <mentor-description />
        <mentoring-mentors />
        <mentoring-profit />
        <mentoring-steps />
        <mentoring-contact />
      </div>
    </div>
    <page-footer/>
    <modal-recall-me />
  </span>
</template>

<script>
import PageFooter from "../components/PageFooter";
import AboutMentoring from "@/components/mentoring/AboutMentoring.vue";
import MentorDescription from "@/components/mentoring/MentorDescription.vue";
import MentoringProfit from "@/components/mentoring/MentoringProfit.vue";
import MentoringSteps from "@/components/mentoring/MentoringSteps.vue";
import MentoringMentors from "@/components/mentoring/MentoringMentors.vue";
import MentoringContact from "@/components/mentoring/MentoringContact.vue";
import ModalRecallMe from "@/components/ModalRecallMe.vue";
export default {
  name: "HowTo",
  components: {
    ModalRecallMe,
    MentoringContact,
    MentoringMentors,
    MentoringSteps, MentoringProfit, MentorDescription, AboutMentoring, PageFooter
  },
  mounted() {
    this.$parent.init()
  }
}
</script>

<style lang="scss" scoped>
.section__how-learn-begin .section__subsection-wrapper {
  margin-top: 0;
  padding: 20px;
}
.section__how-learn-img {
  height: 40rem;
  margin-bottom: 20px;
}
.section.section__coaches {
  min-height: auto;
  padding: 0;
}
.section__how-learn-basic {
  padding-bottom: 5rem;
}

</style>